w<template>
  <v-container
    class="sa-host-container drawer-detection-container"
    fluid
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            class="sa-host-table-display"
            :headers="headers"
            :items="displayHosts"
            :loading="currentPageLoading"
            :server-items-length="totalCount"
            @update:options="paginate($event)"
            :footer-props="footerProps"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Hosts</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="hosts-add-btn"
                  @click="routeToCreateHost"
                >
                  New Host
                </v-btn>
                <HostDialog ref="editHostDialog" @onAddOrSave="reloadPage" />
                <ConfirmationDialog ref="deleteDialog" />
              </v-toolbar>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <div class="d-flex align-center justify-space-between">
                <router-link
                  :to="`/hosts/${item.id}?tab=dashboard`"
                  class="router-link-active"
                >
                  <span>{{ item.name }}</span>
                </router-link>
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-1"
                      x-small
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="copyAccountId(item)"
                    >
                      <span class="copy-btn-text">{{item.accountId}}</span>
                    </v-btn>
                  </template>
                  <span>
                    Account Id
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:[`item.primaryAddress`]="{ item }">
              {{item.primaryAddress}}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <HostStatusComponent :p-selected-host="item" />
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ moment(item.createdAt).format(dateTimeMomentFormat) }}
            </template>

            <template v-slot:[`item.updatedAt`]="{ item }">
              {{ moment(item.updatedAt).format(dateTimeMomentFormat) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-icon
                  medium
                  class="hosts-delete-btn"
                  color="red"
                  @click="openDeleteHostDialog(item)"
                  >mdi-delete</v-icon
                >
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import {
  paginateTable, dateTimeMomentFormat, copyText, logAndExtractMessage, dismissAction,
} from "@/utils/utils";
import HostStatusComponent from "./custom-components/HostStatusComponent";

export default {
  name: "Hosts",

  components: {
    HostStatusComponent,
    HostDialog: () => import("./dialogs/HostDialog.vue"),
    ConfirmationDialog: () => import("./dialogs/ConfirmationDialog.vue"),
  },

  data: () => ({
    moment,
    dateTimeMomentFormat,

    localStorageTableSizeKey: "SA.hosts.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      { text: "Host Name", value: "name", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Primary Address", value: "primaryAddress", sortable: false },
      { text: "Contact Name", value: "contactName", sortable: false },
      { text: "Contact Email", value: "contactEmail", sortable: false },
      { text: "Contact Phone", value: "contactPhone", sortable: false },
      { text: "Created at ", value: "createdAt", sortable: false },
      { text: "Updated at", value: "updatedAt", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("hostModule", [
      "currentPageLoading",
      "currentPage",
      "displayHosts",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("hostModule/loadPage", currentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    routeToCreateHost() {
      this.$router.push({ path: "/create-host", query: { fromPage: this.$route.path } });
    },

    openDeleteHostDialog(host) {
      this.$refs.deleteDialog.openDialog({
        header: "Delete confirmation",
        text: `Are you sure you want to delete "${host.name}" host?`,
        submitText: "Delete",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("hostModule/deleteHost", host);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },

    async reloadPage() {
      try {
        await this.$store.dispatch("hostModule/reloadPage");
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    copyAccountId(host) {
      copyText(host.accountId);
    },
  },
};
</script>

<style lang="scss">
.sa-host-container {
  .sa-host-table-display {
    line-height: 1;
    text-overflow: ellipsis;
  }
  .copy-btn-text {
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0px;
  }
}
</style>

<style scoped>
.item-circle {
  height: 20px;
  width: 20px;
  background: black;
  border-radius: 50%;
}

.sa-host-container >>> table {
  table-layout: fixed;
}
</style>
