<template>
  <div class="d-flex align-center host-status-component" v-if="pSelectedHost">
    <v-chip
        class="host-status-chip"
        :class="chipColor"
    >
      <span class="host-status-name">{{ pSelectedHost.getHostDisplayStatus() }}</span>
    </v-chip>
  </div>
</template>

<script>
import {
  HOST_STATUS_DRAFT,
  HOST_STATUS_ACTIVE,
  HOST_STATUS_INACTIVE,
} from "../../utils/utils";

export default {
  name: "HostStatusComponent",

  props: {
    pSelectedHost: Object,
  },

  computed: {

    chipColor() {
      if (this.pSelectedHost.status == HOST_STATUS_DRAFT) return ["grey", "white--text"];

      if (this.pSelectedHost.status == HOST_STATUS_ACTIVE) return ["green", "white--text"];

      if (this.pSelectedHost.status == HOST_STATUS_INACTIVE) return ["red", "white--text"];

      return "";
    },
  },

};
</script>

<style scoped lang="scss">
.team-status-icon {
  font-size: 20px !important;
}
</style>
